import { PageProps } from "gatsby";
import React from "react";

import { Index } from "../../components/pages/Index";

import { dnProps } from "../../js/utils";

const IndexPage = (props: PageProps) => <Index {...{ ...dnProps, ...props }} />;

export default IndexPage;
